<template>
    <div class="vue-phone-number-input">
        <vue-phone-number-input v-model="phone"
            :only-countries="this.onlyCountries"
            :no-country-selector="noCountrySelector"
            :no-example="noExample"
            :default-country-code="defaultCountryCode"
            :translations="translations"
            :disabled="disabled"
            :no-validator-state="true"
            :error="validateError ? true:false"
            :error-color="'#ff9eae'"
            @input="handleInput"
        />
        <span class="invalid-feedback-custom" v-show="validateError" v-html="validateError"/>
    </div>
</template>
<script>
    export default {
        props: {
            value: {
                type: String,
                default: null
            },
            onlyCountries: {
                type: Array,
                default: null
            },
            noCountrySelector: {
                type: Boolean,
                default: false
            },
            noExample: {
                type: Boolean,
                default: false
            },
            defaultCountryCode: {
                type: String,
                default: 'TR'
            },
            disabled: {
                type: Boolean,
                default: false
            },
            validateError: {
                type: String,
                default: ''
            }
        },
        created() {
            this.phone = this.value
        },
        data() {
            return {
                phone: null,
                translations: {
                    countrySelectorLabel: '',
                    countrySelectorError: '',
                    phoneNumberLabel: '',
                    example: '',
                }
            }
        },
        methods: {
            handleInput(event) {
                this.$emit('input', event)
            }
        }
    }
</script>

<style>
    .vue-phone-number-input .input-tel__input{
        border: 1px solid #e9ecef;
        padding: 12px;
    }
    .vue-phone-number-input .input-tel__label{
        display: none;
    }
    .vue-phone-number-input .input-tel.has-hint .input-tel__input,  .vue-phone-number-input .input-tel.has-value .input-tel__input{
        padding-top: 12px;
    }

    .vue-phone-number-input .input-tel.is-disabled .input-tel__input{
        border: 1px solid #e9ecef;
        background-color: #f5f6f7;
        color: #343a40;
    }
</style>
