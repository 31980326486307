import Api from '@/services/Index'

const sendSms = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.post('/conservatory/application/start', data)
}

const verifySms = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.post('/conservatory/application/verify', data)
}

const login = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.post('/conservatory/application/login', data)
}

const updatePersonalInfo = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.post('/conservatory/application/update-personal-information', data)
}

const updateEducationInfo = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.post('/conservatory/application/update-education-information', data)
}

const updateApplicationInfo = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.post('/conservatory/application/update-application-information', data)
}

const passwordReminder = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.post('/conservatory/application/password-reminder', formData)
}

const downloadFileApplicationSide = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.post('/conservatory/application/download-document', formData, {responseType: 'arraybuffer'})
}

/// Admin
const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/conservatory/applications', config)
}

const update = async (formData, id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.put('/conservatory/applications/'+id, formData)
}

const downloadFile = async (applicationId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/conservatory/applications/download-file/'+applicationId, {responseType: 'arraybuffer'})
}

const sendMessage = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.post('/conservatory/application/messages', formData)
}

const updateMessage = async (formData, id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.put('/conservatory/application/messages/'+id, formData)
}

const deleteMessage = async (messageId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.delete('/conservatory/application/messages/'+messageId)
}

const bulkMessage = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.post('/conservatory/applications/bulk-message', formData)
}


export default {
    sendSms,
    verifySms,
    login,
    updatePersonalInfo,
    updateEducationInfo,
    updateApplicationInfo,
    getAll,
    passwordReminder,
    update,
    downloadFile,
    downloadFileApplicationSide,
    sendMessage,
    updateMessage,
    deleteMessage,
    bulkMessage

}
