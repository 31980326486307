<template>
    <layout>
        <div class="w-100 h-100 d-flex flex-column">
            <div class="d-flex justify-content-end p-4 pb-0">
                <button @click="goToLogin" class="btn btn-light btn-40">
                    <i class="ri-arrow-left-line"></i>
                </button>
            </div>
            <div class="flex-grow-1 w-100 d-flex flex-column p-4 justify-content-center">
                <div>
                    <div class="d-flex flex-column flex-lg-row align-items-center">
                        <div class="logo-wrapper mb-5">
                            <img src="@/assets/img/bau-logo-for-light.svg" height="40" />
                        </div>
                        <h4 class="flex-grow-1 text-center mb-5">
                            {{ $t('conservatory') }} {{ $t('application_system') }}
                        </h4>
                    </div>
                    <div class="fadeIn">
                        <div>
                            <ValidationObserver ref="formDataValidation">
                                <div class="border p-4 bg-white" style="margin-top: -2px; border-bottom-right-radius: 10px; border-bottom-left-radius: 10px">
                                    <b-row>
                                        <b-col cols="12" md="6">
                                                <ValidationProvider name="national_id" rules="required" v-slot="{ errors }">
                                                    <b-form-group :label="$t('national_id')">
                                                        <b-form-input type="number" v-model="formData.national_id"
                                                                      maxlength="11" :state="errors[0] ? false : null" />
                                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                                    </b-form-group>
                                                </ValidationProvider>
                                        </b-col>
                                        <b-col cols="12" md="6">
                                            <ValidationProvider name="birthdate" rules="required" v-slot="{ errors }">
                                                <b-form-group :label="$t('birthdate')">
                                                    <select-date v-model="formData.birthdate"
                                                                 :validationError="errors[0]" />
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col cols="12" md="6">
                                            <ValidationProvider name="name" rules="required" v-slot="{ errors }">
                                                <b-form-group :label="$t('name')">
                                                    <b-form-input v-model="formData.name" :state="errors[0] ? false : null" />
                                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col cols="12" md="6">
                                            <ValidationProvider name="surname" rules="required" v-slot="{ errors }">
                                                <b-form-group :label="$t('surname')">
                                                    <b-form-input v-model="formData.surname"
                                                                  :state="errors[0] ? false : null" />
                                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col cols="12" md="6">
                                            <ValidationProvider name="father_name" rules="required" v-slot="{ errors }">
                                                <b-form-group :label="$t('father_name')">
                                                    <b-form-input v-model="formData.father_name"
                                                                  :state="errors[0] ? false : null" />
                                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col cols="12" md="6">
                                            <ValidationProvider name="mobile_tel" rules="required" v-slot="{ errors }">
                                                <b-form-group :label="$t('gsm_no')">
                                                    <phone-number-input v-model="formData.mobile_tel" :only-countries="['TR']"
                                                                        :no-country-selector="true" :no-example="true"
                                                                        :disabled="process != null ? true : false" />
                                                    <b-form-invalid-feedback v-if="errors[0]"> {{ errors[0].replace("13", "10") }} </b-form-invalid-feedback>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                </div>
                                <div class="d-flex justify-content-center mt-3" v-if="!process">
                                    <h6 v-if="!dateStatus">{{ $t('undergraduate_check_date_message') }} </h6>
                                </div>
                                <div class="d-flex justify-content-center mt-3" v-if="!process">
                                    <processing-button :processing="formStart" variant="primary"
                                                       :label="$t('send_sms').toUpper()"
                                                       @click="start" :disabled="!dateStatus" />
                                </div>
                                <div class="border rounded pt-1 pb-2 pl-4 pr-4 mb-4 bg-white" style="margin-top: -10px;  border-top-right-radius: 0 !important; border-top-left-radius: 0 !important;" v-show="process">
                                    <div class="smsForm">
                                        <div class="fadeIn">
                                            <ValidationProvider name="pin" :rules="process == 'verify' ? 'required|length:6' : ''" v-slot="{ errors }">
                                                <div class="mb-2">
                                                    <sms-input v-model="formData.pin" :timerFrom.sync="smsTimer"
                                                               @timerExpired="timerExpired" />
                                                </div>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center" v-if="process">
                                    <processing-button :processing="formStart" variant="primary"
                                                       :label="$t('send_sms').toUpper()" @click="start" v-if="process == 'startAgain'" />
                                    <processing-button :processing="formVerify" variant="primary"
                                                       :label="$t('verify').toUpper()" @click="verify" v-if="process == 'verify'" />
                                </div>
                                <div class="col-12 mt-4">
                                    {{$t('conservatory_contact_info')}}
                                </div>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>
            </div>
            <vue-recaptcha ref="recaptcha" @verify="onCaptchaVerified" size="invisible"
                           sitekey="6LelIscaAAAAAA0ODBaTFs_FUqS3WTgy-G0bP1pG" />
        </div>
    </layout>
</template>

<script>
// Layout
import Layout from '@/modules/conservatoryApplication/layout/Layout'

// Components
import SelectDate from '@/components/interactive-fields/SelectDate'
import SmsInput from '@/components/elements/SmsInput'
import PhoneNumberInput from '@/components/elements/PhoneNumberInput'
import ProcessingButton from '@/components/elements/ProcessingButton'

import { VueRecaptcha } from 'vue-recaptcha'

// Services
import ConservatoryService from "@/services/ConservatoryService"

// Other
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
    components: {
        Layout,
        VueRecaptcha,
        SelectDate,
        SmsInput,
        PhoneNumberInput,
        ProcessingButton,
        ValidationObserver,
        ValidationProvider
    },
    metaInfo() {
        return {
            title: this.$t("conservatory")+' '+this.$t('application_system'),
        };
    },
    data() {
        return {
            process: null,
            formStart: false,
            formVerify: false,
            formData: {
                national_id: null,
                name: null,
                surname: null,
                birthdate: null,
                father_name: null,
                mobile_tel: null,
                pin: null
            },
            dateStatus: true,
            smsTimer: null,
        }
    },
    watch: {

    },
    async created() {

    },
    methods: {
        onCaptchaVerified(recaptchaToken) {
            this.formData.g_recaptcha_token = recaptchaToken;
            let formData = { ...this.formData }
            if (formData.mobile_tel) {
                formData.mobile_tel = formData.mobile_tel.replace(/[^\d]/g, '');
            }

            this.formStart = true
            ConservatoryService.sendSms(formData).then(response => {
                this.process = 'verify'
                this.smsTimer = parseInt(response.data.data.express_in)
            }).catch(e => {
                if (e.status == 423) {
                    this.$toast.error(this.$t('api.' + e.data.message));
                    setTimeout(() => {
                        this.$router.push('/conservatory/application/login');
                    }, 1000)
                }
                else {
                    this.showErrors(e, this.$refs.formDataValidation)
                }
            }).finally(() => {
                this.formStart = false
                this.$refs.recaptcha.reset();
            })
        },

        async start() {
            const isValid = await this.$refs.formDataValidation.validate();
            if (isValid) {
                this.$refs.recaptcha.execute()
            }
        },

        async verify() {
            const isValid = await this.$refs.formDataValidation.validate();
            if (isValid) {
                let formData = {
                    national_id: this.formData.national_id,
                    pin: this.formData.pin
                }

                this.formVerify = true;
                ConservatoryService.verifySms(formData).then(() => {
                    let loginForm = {
                        national_id: this.formData.national_id,
                        pin: this.formData.pin
                    }

                    // Login
                    ConservatoryService.login(loginForm).then(response => {
                        let data = response.data.data;

                        // Data
                        if (data) {
                            this.$store.commit('conservatoryApplication/setData', data)
                        }

                        // National ID
                        if (this.formData.national_id) {
                            this.$store.commit('conservatoryApplication/setNationalID', this.formData.national_id)
                        }

                        // Pin
                        if (this.formData.pin) {
                            this.$store.commit('conservatoryApplication/setPin', this.formData.pin)
                        }
                    }).then(() => {
                        this.$router.push('/conservatory/application/form')
                    })
                }).catch(e => {
                    this.showErrors(e, this.$refs.formDataValidation)
                }).finally(() => {
                    this.formVerify = false
                    this.$refs.recaptcha.reset()
                })
            }
        },

        timerExpired(event) {
            this.smsTimer = null
            this.process = 'startAgain'
        },

        goToLogin() {
            this.$router.push('/conservatory/application/login');
        }
    }
}
</script>

<style>
#applicationTypeButtons {
    width: 100%;
    display: flex;
}

#applicationTypeButtons label {
    flex-grow: 1;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

#applicationTypeButtons label.active {
    background: #00aeef !important;
    color: #fff !important;
    position: relative;
    z-index: 10;
}

#applicationTypeButtons label.active::after {
    content: "";
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    z-index: 10;
    border: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    margin-left: -6px;
    left: 50%;
    border-top: 6px solid #00aeef;
    bottom: -6px;
}
</style>